.bootstrap-tagsinput {
  border-top-color: darken(@input-border, 8%);
  .box-shadow(none);
  background: #f5f5f5 url('/assets/images/element/bg_form_input.png') repeat-x scroll 0 -5px;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  display: inline-block;
  padding: 4px 6px;
  border: 1px solid @table-border-color;
  margin-bottom: 10px;
  color: #555;
  vertical-align: middle;
  max-width: 100%;
  min-width: 50%;
  line-height: 22px;
  border-radius: 2px;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .tag {
    margin-right: 2px;
    color: white;

    [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;
      &:after {
        font-family: FontAwesome;
        content: "\f057";
        padding: 0px 2px;
      }
      &:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }
}
