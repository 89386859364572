//
// Tooltips
// --------------------------------------------------


// Tooltip body
//

.tooltip-inner {
  background-color: @tooltip-bg;
  font-size: 12px;
  padding: 4px 10px;
  max-width: 500px;
  white-space: nowrap;
}


// Tooltip arrows
//

.tooltip {
  &.top .tooltip-arrow {
    border-top-color: @tooltip-bg;
  }
  &.top-left .tooltip-arrow {
    border-top-color: @tooltip-bg;
  }
  &.top-right .tooltip-arrow {
    border-top-color: @tooltip-bg;
  }
  &.right .tooltip-arrow {
    border-right-color: @tooltip-bg;
  }
  &.left .tooltip-arrow {
    border-left-color: @tooltip-bg;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: @tooltip-bg;
  }
  &.bottom-left .tooltip-arrow {
    border-bottom-color: @tooltip-bg;
  }
  &.bottom-right .tooltip-arrow {
    border-bottom-color: @tooltip-bg;
  }
}


// Colors
//

.tooltip-warning + .tooltip {
  .pixel-tooltip-state(darken(@warning-color, 10%));
}

.tooltip-danger + .tooltip {
  .pixel-tooltip-state(darken(@danger-color, 10%));
}

.tooltip-success + .tooltip {
  .pixel-tooltip-state(darken(@success-color, 10%));
}

.tooltip-info + .tooltip {
  .pixel-tooltip-state(darken(@info-color, 10%));
}