//
// Tables
// --------------------------------------------------

// Tables
//

.table {
  // Cells
  thead,
  tbody,
  tfoot {
    > tr > th,
    > tr > td {
      border-top-color: @table-border-color;
    }

    > tr > th {
      font-weight: 600;
    }
  }
  // Account for multiple tbody instances
  tbody + tbody {
    border-top-color: @table-border-color;
  }
}

// Bordered tables
//

.table-bordered {
  border-color: @table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr > th,
    > tr > td {
      border-color: @table-border-color;
    }

    > tr > th:first-child,
    > tr > td:first-child {
      border-left: none;
    }

    > tr > th:last-child,
    > tr > td:last-child {
      border-right: none;
    }
  }
}

// Table header
//

.table-header {
  background: #fff;
  position: relative;
  border: 1px solid @table-border-color;
  margin-bottom: -1px;
  padding: 10px 15px 10px 15px;
  .border-top-radius(@border-radius-base);

  .table-caption {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}

// Table footer
//

.table-footer {
  min-height: 40px;
  margin-top: -19px;
  position: relative;
  border: 1px solid @table-border-color;
  line-height: 20px;
  padding: 10px 15px 10px 15px;
  background: #fafafa;
  .border-bottom-radius(@border-radius-base);
}

// Colors
//

.table-light,
.table-success,
.table-danger,
.table-warning,
.table-info,
.table-primary {
  margin-bottom: 20px;
}

.table-light {
  .table-color(#fafafa, @text-color);
}

.table-success {
  .table-color(@success-color);
}

.table-danger {
  .table-color(@danger-color);
}

.table-warning {
  .table-color(@warning-color);
}

.table-info {
  .table-color(@info-color);
}

.table-responsive.forced {
  width: 100%;
  margin-bottom: 13.5px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: none;
  -webkit-overflow-scrolling: touch;
}

.table-responsive.forced .table {
  margin-bottom: 0;
  min-width: 700px;
}

.table-responsive.forced .table > thead > tr > th,
.table-responsive.forced .table > tbody > tr > th,
.table-responsive.forced .table > tfoot > tr > th,
.table-responsive.forced .table > thead > tr > td,
.table-responsive.forced .table > tbody > tr > td,
.table-responsive.forced .table > tfoot > tr > td {
  white-space: nowrap;
}

.table-responsive.forced .table-bordered {
  border: 0;
}

.table-responsive.forced .table-bordered > thead > tr > th:first-child,
.table-responsive.forced .table-bordered > tbody > tr > th:first-child,
.table-responsive.forced .table-bordered > tfoot > tr > th:first-child,
.table-responsive.forced .table-bordered > thead > tr > td:first-child,
.table-responsive.forced .table-bordered > tbody > tr > td:first-child,
.table-responsive.forced .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.table-responsive.forced .table-bordered > thead > tr > th:last-child,
.table-responsive.forced .table-bordered > tbody > tr > th:last-child,
.table-responsive.forced .table-bordered > tfoot > tr > th:last-child,
.table-responsive.forced .table-bordered > thead > tr > td:last-child,
.table-responsive.forced .table-bordered > tbody > tr > td:last-child,
.table-responsive.forced .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.table-responsive.forced .table-bordered > tbody > tr:last-child > th,
.table-responsive.forced .table-bordered > tfoot > tr:last-child > th,
.table-responsive.forced .table-bordered > tbody > tr:last-child > td,
.table-responsive.forced .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}
