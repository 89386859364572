//
// Input groups
// --------------------------------------------------


// Buttons height == inputs height
.input-group-btn > .btn {
  line-height: 16px;
  margin: 0;
}

.input-group input {
  position: relative;
  z-index: 3;
}

// Remove double borders
.input-group > .input-group-btn + input {
  margin-left: -1px;
}

.input-group-addon > .fa {
  font-size: 14px;
}

.input-group > input + .input-group-btn .btn:first-child {
  border-left-width: 0;
}

.input-group-addon.no-background {
  background: @input-bg !important;
}