//
// Main navbar
// --------------------------------------------------

.main-navbar-fixed #main-navbar {
  position: fixed !important;
}

// ************************************************************************* //
//**   Small screens (mobile first)                                        **//

#main-navbar {

  // Base styles
  //

  border: none;
  border-radius: 0;
  left: 0;
  min-height: @main-navbar-height;
  right: 0;
  position: absolute;
  top: 0 !important;
  z-index: @zindex-navbar-fixed;

  .navbar-inner {
    min-height: @main-navbar-height;
    width: 100%;
    position: relative;
    left: 0;
  }


  // Navbar header
  //

  .navbar-header {
    padding: 0;
    position: relative;
    width: 100%;
    z-index: @zindex-navbar-fixed + 2;
  }

  .navbar-brand {
    display: inline-block;
    float: none;
    font-size: 14px;
    line-height: @main-navbar-height;
    height: auto !important;
    padding: 0 0 0 14px;
  }


  // Navbar buttons
  //

  #main-menu-toggle,
  .navbar-toggle {
    background: none;
    border: none;
    display: block;
    font-size: 11px;
    margin: 0;
    outline: none !important;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0 !important;
    z-index: @zindex-navbar-fixed + 3;
    
    // Icon
    .navbar-icon {
      font-size: 14px;
      line-height: @main-navbar-height;
    }
  }

  // Menu toggle
  #main-menu-toggle {
    height: @main-navbar-height;
    left: 0;
    overflow: hidden;
    width: @main-menu-collapsed-width;
    z-index: @zindex-navbar-fixed + 4;

    // Icon
    .navbar-icon {
      left: 20px;
      margin: (-1 * (@main-navbar-height / 2)) 0 0 0;
      padding: 0;
      position: absolute;
      z-index: @zindex-navbar-fixed + 5;
      .transition(all .2s);

      // Caret
      &:after {
        content: "\f0da";
        display: block;
        font-size: 10px;
        line-height: @main-navbar-height;
        position: absolute;
        right: -8px;
        top: 0;
      }
    }

    .main-menu-right & .navbar-icon {
      & { left: 26px; }
      &:after { content: "\f0d9"; right: 16px; }
    }

    // Text
    .hide-menu-text {
      left: @main-menu-collapsed-width - 5px;
      line-height: @main-navbar-height;
      margin-top: -1 * (@main-navbar-height / 2);
      opacity: 0;
      position: absolute;
      z-index: @zindex-navbar-fixed + 5;

      .ie8 & {
        left: @main-menu-collapsed-width - 3px;
      }
    }
  }

  // Navbar toggle
  .navbar-toggle {
    padding: 0 17px;
    right: 0;

    &:not(.collapsed) {
      .opacity(.5);
    }

    // Icon
    .navbar-icon {
      display: inline-block;
    }
  }


  // Navbar Items
  //

  li {
    .divider {
      display: none;
    }

    .nav-header {
      display: none;
    }

    a {
      line-height: 42px;
      padding-top: 0;
      padding-bottom: 0;

      &:hover {
        background: none;
      }
  
      &:active,
      &:focus {
        background: none;
      }
    }
  }


  // Navbar collapse
  //

  .navbar-collapse {
    height: auto;
    max-height: inherit;
    padding-left: 0;
    padding-right: 0;
    z-index: @zindex-navbar-fixed + 1;

    &,
    &.collapse {
      overflow: visible;
    }

    &.collapsing,
    &.in {
      overflow: hidden;
    }
  }

  .navbar-nav {
    margin: 0;

    &.pull-right {
      width: 100%;
    }

    > li {
      .clearfix();
    }
  }

  .dropdown-menu {
    padding: 0;
  }


  // Navbar forms
  //

  form.navbar-form {
    border: none;
    margin: 0;
    width: 100%;

    .form-group {
      margin: 0;
    }

    .form-control {
      .box-shadow(none);
      .transition(background .2s);
    }
  }


  // User menu
  //

  .user-menu {
    > img {
      border-radius: 25px;
      display: inline-block;
      height: 20px;
      margin: -2px 0 0 0;
      width: 20px;
    }

    > span {
      display: inline-block;
      margin-left: 7px;
    }
  }
}

// Expanded main menu
//
.mme #main-navbar {
  overflow: hidden;

  #main-menu-toggle {
    width: 80%;

    .navbar-icon { left: 26px; }
    .navbar-icon:after { content: "\f0d9"; right: 16px; }
    .hide-menu-text { opacity: 1; .transition(all 1s); }
  }
}

.mme.main-menu-right #main-navbar #main-menu-toggle {
  .navbar-icon { left: 20px; }
  .navbar-icon:after { content: "\f0da"; right: -8px; }
}

@media (max-width: @screen-tablet) {
  #main-navbar {
    .dropdown > a:after {
      content: "\f078";
      display: inline-block;
      float: right;
      font-family: FontAwesome;
      font-size: 10px;
      position: relative;
    }

    .dropdown.open {
      border-bottom: none;

      > a:after {
        content: "\f077";
      }
    }
  }
}

// ************************************************************************* //
//**   Desktops                                                            **//

@media (min-width: @screen-tablet) {
  #main-navbar {
    overflow: visible !important;


    // Navbar header
    //

    .navbar-header {
      width: auto;
      z-index: @zindex-navbar-fixed + 3;
    }

    .navbar-brand {
      padding-right: 14px;
    }


    // Navbar buttons
    //

    #main-menu-toggle {
      width: @main-menu-collapsed-width !important;

      // Icon
      .navbar-icon {
        left: 26px;

        &:after {
          content: "\f0d9";
          right: 16px;
        }
      }
    }

    .main-menu-right & #main-menu-toggle,
    .mme.main-menu-right & #main-menu-toggle {
      .navbar-icon { left: 20px; }
      .navbar-icon:after { content: "\f0da"; right: -8px; }
    }

    .navbar-toggle {
      display: none !important;
    }

    .hide-menu-text {
      opacity: 0 !important;
      .transition(~"none !important");
    }


    // Navbar Items
    //

    .right {
      left: 0;
      padding-right: @main-navbar-right-padding;
      position: absolute;
      right: 0;
      top: 0;
      z-index: @zindex-navbar-fixed + 1;
    }

    li {
      border: none;

      .divider {
        display: block;
      }
  
      a {
        line-height: @main-navbar-height;
      }
    }


    // Navbar collapse
    //

    .navbar-collapse {
      height: auto !important;
      overflow: visible !important;
      padding: 0 !important;
    }

    .navbar-nav,
    .navbar-form {
      position: relative;
      z-index: @zindex-navbar-fixed + 2;
    }

    .navbar-nav {
      margin: 0;

      > li {
        > a {
          height: @main-navbar-height;
          .transition(all .2s);
        }
      }

      &.pull-right {
        width: auto;
      }
    }


    // Dropdowns
    //

    .dropdown > a:after {
      display: none;
    }

    .dropdown.open {
      border-bottom: none;
    }

    .dropdown-menu {
      .badge {
        padding-left: 6px;
        padding-right: 6px;
      }

      > li > a {
        line-height: 32px;
        margin: 0;
      }
    }

    .pull-right .dropdown-menu {
      float: right;
      left: auto;
      right: 0;
    }


    // Navbar forms
    //

    form.navbar-form {  
      height: @main-navbar-height;

      .form-control {
        @navbar-text-input-height: 30px;
        @navbar-text-input-line-height: 20px;
        @padding-top-bottom: (@navbar-text-input-height - @navbar-text-input-line-height) / 2;

        height: @navbar-text-input-height;
        line-height: @navbar-text-input-line-height;
        margin-top: (@main-navbar-height - @navbar-text-input-height) / 2;
        padding-bottom: @padding-top-bottom;
        padding-top: @padding-top-bottom;
        width: 150px;
      }
    }


    // User menu
    //
    
    .user-menu {
      > span {
        display: none;
      }

      > img {
        height: 25px;
        margin-top: -3px;
        width: 25px;
      }
    }
  }

  // Collapsed main menu
  .mmc #main-navbar #main-menu-toggle,
  .mme.mmc #main-navbar #main-menu-toggle {
    .navbar-icon {
      left: 20px;
    }

    .navbar-icon:after {
      content: "\f0da";
      right: -8px;
    }
  }

  .main-menu-right.mmc #main-navbar #main-menu-toggle,
  .main-menu-right.mme.mmc #main-navbar #main-menu-toggle {
    .navbar-icon { left: 26px; }
    .navbar-icon:after { content: "\f0d9"; right: 16px; }
  }
    
}


// ************************************************************************* //
//**   Large screens                                                       **//

@media (min-width: @screen-desktop) {
  #main-navbar {
    .mmc & .navbar-header {
      width: auto;
    }

    .user-menu > span {
      display: inline-block;
    }
  }

  .no-main-menu #main-navbar .navbar-header,
  .main-menu-right #main-navbar .navbar-header {
    width: auto;
  }
}


// Navbar scrollbar
//

#main-navbar-collapse .iScrollVerticalScrollbar {
  margin-right: 2px !important;
  width: 4px !important;

  .iScrollIndicator {
    background: rgba(255, 255, 255, .2) !important;
    border: none !important;
  }
}