//
// Demo
// --------------------------------------------------

// Logo
//

#main-navbar {
    .navbar-brand {
        font-size: 13px;
        .opacity(1);
        .transition(all .2s);

        &:hover {
            .opacity(.8);
        }

        div {
            display: inline-block;
            position: relative;
            margin-right: 4px;
            top: 4px;

            &, img {
                height: 18px;
                width: 18px;
            }

            img {
                display: block;
            }
        }
    }
}

.right-to-left {
    #main-navbar {
        .navbar-brand div {
            margin-right: 0;
            margin-left: 4px;
        }
    }
}

// Menu content-block example
//

#menu-content-demo {
    white-space: nowrap !important;
    position: relative;
    padding-left: 56px !important;



    img {
        width: 44px;
        height: 44px;
        display: block;
        position: absolute;
        left: -50px;
        top: 1px;
        border-radius: 999999px;
    }

    .text-bg {
        color: #fff;
        margin-bottom: 6px;
        font-size: 12px !important;
    }

    .btn {
        width: 26px !important;
        height: 22px;
        line-height: 20px;
        text-align: center;
        padding: 0;
        display: inline-block !important;
        opacity: .8;
        .transition(all .1s);

        &:hover {
            opacity: 1;
        }
    }

    & > div {
        position: relative;
        width: 120px;
        margin: 50px auto 6px;
        display: block;
    }

    .close {
        position: absolute;
        top: -15px;
        right: -5px;
        text-shadow: none;
        color: #fff;
        color: rgba(255, 255, 255, .2);
        opacity: 1;
        font-weight: 300;
        font-size: 18px;
        .transition(all .2s);

        &:hover {
            color: #fff;
        }
    }
}

.theme-clean #menu-content-demo,
.theme-white #menu-content-demo {
    .text-bg {
        color: #444;
    }

    .close {
        color: #ccc;

        &:hover {
            color: #888;
        }
    }
}

.right-to-left {
    #menu-content-demo {
        padding-left: 20px !important;
        padding-right: 86px !important;

        img {
            left: auto;
            right: -68px;
        }

        .close {
            left: -20px;
            right: auto;
        }
    }
}

@media (min-width: @screen-small) {
    #menu-content-demo > div {
        width: 120px;
        margin-top: 0px;
    }

    #menu-content-demo .close {
        right: -20px;
    }
}