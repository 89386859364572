.angucomplete-holder {
  position: relative;
}

.input-group{
  .angucomplete-dropdown {
    top: 28px;
  }
}

.angucomplete-dropdown {
  background: #fff;
  border-radius: @border-radius-base;
  .box-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
  border: 1px solid #dee5e7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 5px 0;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

.angucomplete-searching {
  color: #acacac;
  padding: 5px 18px;
}

.angucomplete-description {
  font-size: 11px;
}

.angucomplete-row {
  clear: both;
  margin: 0 -2px;
  padding: 5px 18px;
  text-align: left;
}

.angucomplete-selected-row {
  background-color: @brand-primary;
  color: #ffffff;
}

.angucomplete-image-holder {
  padding-top: 2px;
  float: left;
  margin-right: 10px;
  margin-left: 5px;
}

.angucomplete-image {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-color: #ececec;
  border-style: solid;
  border-width: 1px;
}

.angucomplete-image-default {
  /* Add your own default image here
   background-image: url('/assets/default.png');
  */
  background-position: center;
  background-size: contain;
  height: 34px;
  width: 34px;
}