//
// Badges
// --------------------------------------------------


// Default badges
//

.badge {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: @label-tag-height - 2px;
  padding: 0 8px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);

  &.pull-right {
    margin: 0;
  }
}


// Colors
//

.badge {
  .pixel-badge(#b0b0b0);
}

.badge.badge-success {
  .pixel-badge(@success-color);
}

.badge.badge-warning {
  .pixel-badge(@warning-color);
}

.badge.badge-danger {
  .pixel-badge(@danger-color);
}

.badge.badge-info {
  .pixel-badge(@info-color);
}

