//
// Progress bars
// --------------------------------------------------


// Default progress bars
//

.progress {
  background-color: rgba(0, 0, 0, .06);
  height: 13px; 
}

.progress-bar {
  background: none;
  border: 1px solid;
  .box-shadow(none);
}


// Colors
//

.progress .progress-bar.progress-bar-info {
  .pixel-progress-bar(@info-color);
}
.progress.progress-striped .progress-bar.progress-bar-info {
  .pixel-progress-striped(@info-color);
}

.progress .progress-bar.progress-bar-success {
  .pixel-progress-bar(@success-color);
}
.progress.progress-striped .progress-bar.progress-bar-success {
  .pixel-progress-striped(@success-color);
}

.progress .progress-bar.progress-bar-warning {
  .pixel-progress-bar(@warning-color);
}
.progress.progress-striped .progress-bar.progress-bar-warning {
  .pixel-progress-striped(@warning-color);
}

.progress .progress-bar.progress-bar-danger {
  .pixel-progress-bar(@danger-color);
}
.progress.progress-striped .progress-bar.progress-bar-danger {
  .pixel-progress-striped(@danger-color);
}